<script>
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction, postAction, putAction } from '@/command/netTool'
import { subMerTypeList, subMerRuleTypeList } from '@/utils/textFile'

export default {
  name: 'secondary',
  data() {
    return {
      ...api.command.getState(),
      secondaryDataList: [],
      shopDataList: [],
      shopDataList2: []
    }
  },
  mounted() {
    this.getInit()
  },
  methods: {
    getInit() {
      api.command.getList.call(this, {
        url: '/commodity/farmShopSubMerchant/list',
        current: 1
      })
    },
    getHeader() {
      return [
        {
          name: '二级商户名',
          key: 'subMerName',
          type: 'input'
        },
        {
          name: '电话',
          type: 'input',
          key: 'mobilePhone'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'subMerId',
          title: '二级商户编号',
          align: 'left',
          sorter: (a, b) => a.subMerId - b.subMerId
        },
        {
          dataIndex: 'subMerName',
          title: '二级商户名',
          align: 'left',
          sorter: (a, b) => a.subMerName.length - b.subMerName.length
        },
        {
          dataIndex: 'shopName',
          title: '所属店铺',
          align: 'left',
          sorter: (a, b) => a.shopName.length - b.shopName.length
        },
        {
          dataIndex: 'subMerType',
          title: '商户类型',
          align: 'left',
          customRender: text => (subMerTypeList.find(e => e.value == text) || { name: '' }).name,
          onExport: text => (subMerTypeList.find(e => e.value == text) || { name: '' }).name
        },
        {
          dataIndex: 'status',
          title: '验证',
          type: 'badge',
          align: 'center',
          filters: subMerRuleTypeList,
          onExport: records => {
            return {
              0: '待验证',
              1: '等待审核',
              2: '开通成功',
              3: '开通失败'
            }[records]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: {
                0: '等待验证',
                1: '等待审核',
                2: '开通成功',
                3: '开通失败'
              }[data],
              color: {
                0: 'red',
                1: 'red',
                2: 'green',
                3: 'red'
              }[data]
            }
          }
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                display: records.status == 0 || records.status == 3,
                name: '发短信',
                onClick: () => {
                  postAction('/commodity/farmShopSubMerchant/sendMerchant?id=' + records.id).then(res => {
                    if (res.code == 200) {
                      this.$message.success('短信发送成功！')
                    } else {
                      this.$message.error(res.msg)
                    }
                  })
                }
              },
              {
                display: records.status == 0 || records.status == 3,
                name: '验证',
                onClick: () => {
                  this.handlePopup(records)
                }
              },
              {
                display: true,
                name: '上传照片',
                onClick: () => {
                  getAction(`/commodity/farmShopSubMerchant/detail/${records.id}`, {}).then(res => {
                    if (res.code == 200) {
                      this.handleUpload(res.data)
                    } else {
                      this.$message.error(res.mag)
                    }
                  })
                }
              },
              {
                display: records.status == 2,
                name: '关联',
                onClick: () => {
                  this.handlePopup2(records)
                }
              },
              {
                display: records.status != 2,
                name: '编辑',
                onClick: () => this.$router.push('/marketingCenter/secondaryDetail?id=' + records.id)
              },
              {
                display: true,
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.delPost.call(this, {
                    url: `/commodity/farmShopSubMerchant/delete/${records.id}`
                  })
              }
            ].filter(e => e.display)
          }
        }
      ]
    },
    getUploadForm(source, formData) {
      const form = [
        {
          form: [
            {
              name: '营业执照ZIP压缩包（最大1.5MB）',
              type: 'uploadZip',
              key: 'subMerCertFileOne',
              cols: 24
            }
          ]
        },
        {
          form: [
            {
              name: '法人/负责人身份证正反面ZIP压缩包（正面、反面ZIP压缩包。最大1.5MB）',
              type: 'uploadZip',
              key: 'subMerCertFileTwo',
              cols: 24
            }
          ]
        },

        {
          form: [
            {
              name: '辅助材料（1. 个人银行卡拍照  2. 农行卡、最大1.5MB）',
              type: 'uploadZip',
              key: 'subMerCertFileThree',
              cols: 24
            }
          ]
        }
      ]
      return form
    },
    handleUpload(dataSourse) {
      let that = this
      apiTool.showDrawer({
        title: '上传照片',
        width: '600px',
        view: DrawerForm,
        viewProps: {
          data: formData => that.getUploadForm(dataSourse, formData),
          form: dataSourse,
          formProps: {
            cols: 24
          }
        },
        success: ({ data, setHidden }) => {
          if (!(data.subMerCertFileOne && data.subMerCertFileThree && data.subMerCertFileTwo)) {
            this.$message.warning('请上传附件！')
            return
          }
          putAction('/commodity/farmShopSubMerchant/uploadFile', {
            id: data.id,
            subMerCertFileOne: data.subMerCertFileOne,
            subMerCertFileThree: data.subMerCertFileThree,
            subMerCertFileTwo: data.subMerCertFileTwo,
            subMerId: data.subMerId
          }).then(res => {
            if (res.code == 200) {
              that.$message.success('提交成功')
              that.getInit()
              setHidden()
            } else {
              that.$message.warning(res.msg)
            }
          })
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '确认上传',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    getDrawerForm(source, formData) {
      const form = [
        {
          form: [
            {
              name: '转账金额',
              type: 'input',
              key: 'price',
              is_show: source.accountType == '601'
            },
            {
              name: '验证码',
              type: 'input',
              key: 'code',
              is_show: source.accountType == '401'
            }
          ].filter(e => e.is_show)
        }
      ]
      return form
    },
    handlePopup(dataSourse) {
      let that = this
      apiTool.showDrawer({
        title: '验证',
        width: '600px',
        view: DrawerForm,
        viewProps: {
          data: formData => that.getDrawerForm(dataSourse, formData),
          form: dataSourse,
          formProps: {
            cols: 24,
            labelCol: { span: 3 },
            wrapperCol: { span: 21 }
          }
        },
        success: ({ data, setHidden }) => {
          data.price = data.price === undefined ? '' : data.price
          data.code = data.code === undefined ? '' : data.code

          postAction(
            `/commodity/farmShopSubMerchant/merchantCompile?id=${data.id}&price=${data.price}&code=${data.code}`
          ).then(res => {
            if (res.code == 200) {
              this.$message.success('提交验证')
              this.getInit()
              setHidden()
            } else {
              this.$message.warning(res.msg)
            }
          })
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    async onShopSearch(value = '') {
      const searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value ?? ''}`
      })
      this.shopDataList = searchResult.map(el => {
        return {
          name: el.mainBodyName,
          value: el.id
        }
      })
    },
    async onShopSearch2(value = '') {
      const searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmShopSubMerchant/querySubMerList?subMerName=${value ?? ''}`
      })
      this.shopDataList2 = searchResult.map(el => {
        return {
          name: el.subMerName,
          value: el.id
        }
      })
    },
    getDrawerForm2(source, formData) {
      const form = [
        {
          form: [
            {
              name: '店铺',
              type: 'dropDownInput',
              key: 'shopName',
              onInputSearch: this.onShopSearch,
              onMenuClick: this.onShopClick,
              typeData: this.shopDataList,
              valueKey: 'shopId',
              inputProps: {
                addonBefore: <a-icon type="search" />
              }
            }
          ]
        }
      ]
      return form
    },
    handlePopup2(dataSourse) {
      let params = Object.assign({}, dataSourse)
      apiTool.showDrawer({
        title: '关联二级商户',
        width: '600px',
        view: DrawerForm,
        viewProps: {
          data: formData => this.getDrawerForm2(params, formData),
          form: params,
          formProps: {
            cols: 24,
            labelCol: { span: 3 },
            wrapperCol: { span: 21 }
          }
        },
        success: ({ data, setHidden }) => {
          const { id, shopId } = data
          putAction(`/commodity/farmShopSubMerchant/bindShop`, { id, shopId }).then(res => {
            if (res.code == 200) {
              this.$message.success('操作成功')
              this.getInit()
              setHidden()
            } else {
              this.$message.warning(res.msg)
            }
          })
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    getDrawerForm3() {
      const form = [
        {
          form: [
            {
              name: '二级商户',
              type: 'dropDownInput',
              key: 'subMerName',
              onInputSearch: this.onShopSearch2,
              typeData: this.shopDataList2,
              valueKey: 'id',
              inputProps: {
                addonBefore: <a-icon type="search" />
              }
            },
            {
              name: '店铺',
              type: 'dropDownInput',
              key: 'shopName',
              onInputSearch: this.onShopSearch,
              typeData: this.shopDataList,
              valueKey: 'shopId',
              inputProps: {
                addonBefore: <a-icon type="search" />
              }
            }
          ]
        }
      ]
      return form
    },
    handlePopup3() {
      apiTool.showDrawer({
        title: '二级商户关联店铺',
        width: '600px',
        view: DrawerForm,
        viewProps: {
          data: () => this.getDrawerForm3(),
          formProps: {
            cols: 24,
            labelCol: { span: 3 },
            wrapperCol: { span: 21 }
          }
        },
        success: ({ data, setHidden }) => {
          const { id, shopId } = data
          putAction(`/commodity/farmShopSubMerchant/relationSubMerBank`, { id, shopId }).then(res => {
            if (res.code == 200) {
              this.$message.success('操作成功')
              this.getInit()
              setHidden()
            } else {
              this.$message.warning(res.msg)
            }
          })
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/marketingCenter/secondaryDetail')
        },
        {
          name: '关联二级商户',
          type: 'primary',
          onClick: () => {
            this.handlePopup3()
          }
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
